import * as React from "react";
import { useEffect, useState } from "react";
import { IConfig, UserClient } from "../api/rentMyApi";
import { Col, Container, Row } from "reactstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import OtherItems from "../components/item-main/OtherItems";
import Reviews from "../components/item-main/Reviews";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
export function PublicProfile() {
  const searchParams = new URLSearchParams(window.location.search);
  const publicUserId = searchParams.get("id");
  const [userData, setUserData] = useState(null);
  const [reviews, setReviews] = useState([]);

  const { t } = useTranslation();

  const tabs = [
    {
      id: "items",
      value: t("items"),
    },
    {
      id: "reviews",
      value: t("my_profile_reviews"),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [otherItemCount, setOtherItemCount] = useState(0);

  const getUserData = async () => {
    if (!publicUserId) return;
    const client = new UserClient(
      new IConfig("notoken"),
      process.env.REACT_APP_API_ENDPOINT
    );
    const response = await client.detailed52(publicUserId);
    setUserData(response);
  };

  const getReviews = async () => {
    if (!publicUserId) return;
    const userClient = new UserClient(
      new IConfig("notoken"),
      process.env.REACT_APP_API_ENDPOINT
    );
    const response = await userClient.reviews(publicUserId);
    setReviews(response.data);
  };

  const changeTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab.id);
  };

  const getStat = (tab) =>
    tab.id === "reviews" ? reviews.length : otherItemCount;

  const hasBanner = () => userData.bannerImage?.path;

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  useEffect(() => {
    getUserData();
    getReviews();
  }, []);

  return userData ? (
    <>
      {hasBanner() && (
        <div
          className="profile-banner"
          style={{
            backgroundImage: `url('${userData.bannerImage?.path}')`,
          }}
        >
          <div className="banner-cover"></div>
        </div>
      )}
      <div
        className={`user-profile-wrapper ${hasBanner() ? "with-banner" : ""}`}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="user-profile-header">
                {userData.profileImage && (
                  <div
                    className="user-profile-image"
                    style={{
                      backgroundImage: `url('${userData.profileImage?.path}')`,
                    }}
                  ></div>
                )}

                <div className="user-profile-data">
                  <h1>{userData?.name}</h1>
                  <span className="meta">
                    Joined {formatDate(userData.joinedDate)}
                  </span>
                  <span className="meta">
                    <FontAwesomeIcon icon={faLocationDot} />
                    {userData.approximateLocation?.city &&
                      `${userData.approximateLocation.city}, `}
                    {userData.approximateLocation?.country
                      ? `${userData.approximateLocation.country}`
                      : "UK"}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          {userData.aboutMe && userData.aboutMe.length > 0 && (
            <Row>
              <Col xs={12}>
                <div className="user-profile-about">
                  <p>{userData.aboutMe}</p>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <div className="renters-items-and-reviews">
                <div className="general-tab-navigation">
                  {tabs.map((tab) => {
                    const isActive = activeTab === tab.id;
                    const stat = getStat(tab);
                    return (
                      <a
                        href="#_"
                        key={tab.id}
                        onClick={(e) => changeTab(e, tab)}
                        className={isActive ? "active" : ""}
                      >
                        {stat} {tab.value}
                      </a>
                    );
                  })}
                </div>
                {activeTab === "items" ? (
                  <OtherItems
                    userId={userData.id}
                    setOtherItemCount={setOtherItemCount}
                    limit={50}
                  />
                ) : (
                  <Reviews reviews={reviews} />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  ) : null;
}
