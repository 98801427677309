import React, { useState, FormEvent } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

interface ContactFormProps {
  onSubmitSuccess?: () => void;
  onSubmitError?: (error: any) => void;
}

interface FormData {
  name: string;
  email: string;
  message: string;
}

const ContactForm: React.FC<ContactFormProps> = ({
  onSubmitSuccess,
  onSubmitError,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const WEB3FORMS_ACCESS_KEY = '684c77ea-7be4-48af-9063-eb421434b37c';

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage(null);

    if (!formData.name || !formData.email || !formData.message) {
      setErrorMessage('Please fill in all required fields.');
      setSubmitting(false);
      return;
    }

    if (!captchaToken) {
      setErrorMessage('Please complete the CAPTCHA.');
      setSubmitting(false);
      return;
    }

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: WEB3FORMS_ACCESS_KEY,
          name: formData.name,
          email: formData.email,
          message: formData.message,
          captcha: captchaToken,
        }),
      });

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      const result = await response.json();

      if (result.success) {
        setSubmitted(true);
        if (onSubmitSuccess) onSubmitSuccess();
      } else {
        throw new Error(result.message || 'Something went wrong.');
      }
    } catch (error: any) {
      setErrorMessage(
        error.message || 'Oops, something went wrong. Please try again later.'
      );
      if (onSubmitError) onSubmitError(error);
    } finally {
      setSubmitting(false);
    }
  };

  if (submitted) {
    return (
      <div className="contactform">
        <h2>Thank you for your message!</h2>
        <p>We’ll be in touch soon.</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="contactform">
      {errorMessage && <div className="error">{errorMessage}</div>}
      <div className="form-group">
        <label htmlFor="name">
          Name <span>*</span>
        </label>
        <input
          id="name"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">
          Email <span>*</span>
        </label>
        <input
          id="email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="message">
          Message <span>*</span>
        </label>
        <textarea
          id="message"
          name="message"
          rows={5}
          value={formData.message}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group captcha">
        <HCaptcha
          sitekey="e33afa5c-b4d6-4ed8-bb08-36127186d80a"
          onVerify={handleCaptchaChange}
        />
      </div>
      <button type="submit" disabled={submitting}>
        {submitting ? 'Sending...' : 'Send'}
      </button>
    </form>
  );
};

export default ContactForm;