import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Col, Row } from "reactstrap";
import timeDisplay from "../../utils/timeDisplay";
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/layout/Footer";
import { LoadingFullSide } from "../../components/common/LoadingFullScreen";

export function Blog() {
    
    const blogURL = process.env.REACT_APP_BLOG_URL;
    const blogAPIKey = process.env.REACT_APP_BLOG_API_KEY;

    const navigate = useNavigate();
    const [loading, setLoading]: Array<any> = useState(true);
    const [blogPosts, setBlogPosts]: Array<any> = useState([]);

    const getContent = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${blogURL}/posts/?key=${blogAPIKey}&fetchBodies=false&fetchImages=true`);
            setBlogPosts(res.data.items)
        } catch(error) {
            console.error("Error fetching content: ", error);
        }
        setLoading(false)
    }

    const goToPost = (post) => {
        navigate(`/blog/${post.id}`)
    }

    useEffect(() => {
        getContent();
    }, []);

    return loading ? <LoadingFullSide/> : (
        <>
        <div className="height-wrapper">
            <Container className="mt-4 static-pages-wrapper blog">
                <Row>
                    <Col xs={12}>
                        <h1>Latest Stories</h1>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {
                        blogPosts.map(post => {
                            return (
                                <Col xs={12} sm={6} md={6} lg={4} key={post.id}>
                                    <div className="single-entry-wrapper" onClick={() => goToPost(post)}>
                                        <div className="single-entry-image" style={{backgroundImage: `url(${post.images[0].url})`}}></div>
                                        <div className="single-entry-data">
                                            <h2 className="single-entry-title">{post.title}</h2> 
                                            <span className="publish-date">{timeDisplay(post.published)}</span>
                                        </div>                      
                                        <div className="single-entry-publisher">
                                            <div className="left">
                                                <div className="publisher-image" style={{backgroundImage: `url(${post.author.image.url})`}}></div>
                                            </div>
                                            <div className="right">
                                                <span className="published-by-text">Published by</span>
                                                <span className="published-name-text"> {post.author.displayName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                
                </Row>
            </Container>  
        </div>
        <Footer />
        </>
    )

}