/*
    Extract location string from address components
    starting with the most accurate to least
*/
export default function locationFromSearchResult(selectedPlace) {
  let found: string = "";
  [
    "postal_town",
    "sublocality",
    "political",
    "postal_code",
    "sublocality_level_1",
    "administrative_area_level_3",
  ].forEach((type) => {
    selectedPlace?.address_components?.forEach((component) => {
      if (component.types.includes(type) && found === "") {
        found = component.long_name;
      }
    });
  });
  return found;
}
