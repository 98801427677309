import * as React from "react";
import { useEffect, useState } from "react";
import { IConfig, ItemClient } from "../../api/rentMyApi";
import { condenseItemForList } from "../../utils/condenseItemForList";
import ItemHighlight from "../item/ItemHighlight";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OtherItems({ userId, setOtherItemCount, limit }) {
  const { REACT_APP_API_ENDPOINT: endpoint } = process.env;
  const navigate = useNavigate();
  const [otherItems, setOtherItems] = useState(null);
  const { t } = useTranslation();

  const navigateTo = (e, link) => {
    e.preventDefault();
    navigate(link);
  };

  const getSharersOtherItems = async (userId) => {
    const itemDetailClient = new ItemClient(new IConfig("notoken"), endpoint);
    const response = await itemDetailClient.detailed25(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      userId,
      undefined,
      undefined,
      limit || 4,
      1
    );
    setOtherItems(response);
    setOtherItemCount(response.totalDataCount);
  };

  useEffect(() => {
    getSharersOtherItems(userId);
  }, []);

  return (
    <Row className="item-listings">
      {otherItems &&
        otherItems.data &&
        otherItems.data.length > 0 &&
        otherItems.data.map((item) => {
          return (
            <Col xs={6} sm={6} md={6} lg={4} xl={3} key={item.id}>
              <ItemHighlight
                item={condenseItemForList(item)}
                isFocused={false}
                isItemSearch={false}
              />
            </Col>
          );
        })}
      {otherItems && otherItems.totalDataCount > otherItems.dataCount && (
        <Col xs={12}>
          <a
            href="#_"
            className="general-more-link"
            onClick={(e) => navigateTo(e, `/public/profile?id=${userId}`)}
          >
            <span>
              {t("see_more_listings")} {otherItems.totalDataCount} {t("items")}
            </span>
            <FontAwesomeIcon icon={faArrowRight} />
          </a>
        </Col>
      )}
    </Row>
  );
}
