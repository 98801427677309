import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlaceResult = google.maps.places.PlaceResult;
import LocationSearchField from "./LocationSearchField";
import { useWindowSize } from "../../utils/WindowSize";

interface WhatWhereSearchBarDesktopProps {
  handleSubmit: () => Promise<void>;
  localSelectedPlace: google.maps.places.PlaceResult | undefined;
  setLocalSelectedPlace: React.Dispatch<
    React.SetStateAction<PlaceResult | undefined>
  >;
  localSearchTerm: string;
  setLocalSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}


export const WhatWhereSearchBarDesktop: React.FC<WhatWhereSearchBarDesktopProps> =
  ({
    handleSubmit,
    localSelectedPlace,
    setLocalSelectedPlace,
    localSearchTerm,
    setLocalSearchTerm,
  }) => {
    const { t } = useTranslation();
    const {width} = useWindowSize()
    const isMiddleScreen = width <= 1001;
    const [preventFormSubmit, setPreventFormSubmit] = useState(false);

    const handleChangeSubmit = async (
      event: React.FormEvent<HTMLFormElement>
    ) => {
      if (preventFormSubmit) {
        event.preventDefault();
        setPreventFormSubmit(false);
        return;
      }
      event.preventDefault();

      await handleSubmit();
    };

    return (
      <form onSubmit={handleChangeSubmit} className="nav-bar-search">
        <div className="what-for-section">
          <input
            className="search-bar-text-input"
            type="text"
            placeholder={
              isMiddleScreen
                ? t("homepage_what_are_you_looking_for_short")
                : t("homepage_what_are_you_looking_for")
            }
            onChange={(e) => {
              e.preventDefault();
              setLocalSearchTerm(e.target.value);
            }}
            value={ localSearchTerm || ""}
          />
        </div>
        <div className="search-section where">
          <LocationSearchField localSelectedPlace={localSelectedPlace} setLocalSelectedPlace={setLocalSelectedPlace} />
        </div>
        <button className="btn nav-search-bar-btn btn-default">
          <FontAwesomeIcon icon={faMagnifyingGlass} style={{paddingTop: "4px", paddingLeft: "1px"}} />
        </button>
      </form>
    );
  };
