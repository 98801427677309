
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

export function OneWideImage2() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [textTitle, setTextTitle] = useState("");
  const [imagePath1, setImagePath1] = useState("");
  const [imageTitle1, setImageTitle1] = useState("");
  const [imageCaptions1, setImageCaptions1] = useState<string[]>([]);
  const [buttonTitle, setButtonTitle] = useState("");
  const [buttonUrl, setButtonUrl] = useState("");

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const oneWideImagePath = process.env.REACT_APP_CMS_ONEWIDEIMAGE_PATH!;
    const absPath = cmsUrl + oneWideImagePath;

    axios
      .get(absPath)
      .then((res) => {
        const { sectionBackgroundColor } = res.data.fields;

        // Set CSS variable for background color
        document.documentElement.style.setProperty(
          "--oneWideImageSectionBackgroundColor",
          `#${sectionBackgroundColor}`
        );

        // Set component state with new static data
        setTextTitle("The Airbnb of everything!");
        setImagePath1("/assets/img/frontpage/1wide_img2.webp"); // Update if dynamic
        setImageTitle1(
          "Perfect as a side hustle, or to kickstart one without the expense of new equipment or tools."
        );
        setImageCaptions1([
          "Earn More: List the useful items you don't use often and top up your income.",
          "⁠Access More: Get the things you want, when you want them, without the expense of buying.",
          "More Secure: All our users are verified with photo ID to ensure trust within our community.",
        ]);
        setButtonTitle("Get Started");
        setButtonUrl("/account/dashboard"); // Update with your desired URL
      })
      .catch((err) => {
        console.error("Error fetching OneWideImage data:", err);
        // Optionally set error state here
      });
  }, []);

  // Function to handle "Get Started" button click
  const handleButtonClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    navigate(buttonUrl);
  };

  return (
    <section className="product-benefits one-wide-image-background">
      <Container>
        <div className="row align-items-center">
          {/* Text Content */}
          <div className="col-12 col-md-6 mb-4 mb-md-0 order-md-2">
            <ul className="image-captions">
              <h2>{textTitle}</h2>
              <h3>{imageTitle1}</h3>
              {imageCaptions1.map((caption, index) => {
                const words = caption.split(" ");
                const firstTwoWords = words.slice(0, 2).join(" ");
                const remainingText = words.slice(2).join(" ");
                return (
                  <li key={index}>
                    <span className="caption-title">{firstTwoWords}</span>
                    {remainingText}
                  </li>
                );
              })}
            </ul>
            <a
              className="btn btn-secondary ml-15"
              href={buttonUrl}
              onClick={handleButtonClick}
            >
              {buttonTitle}
            </a>
          </div>
          {/* Image Content */}
          <div className="col-12 col-md-6 image-wrapper order-md-1">
            <img
              src={imagePath1}
              alt={imageTitle1 || "Product Image"}
              className="responsive-image"
              loading="lazy"
            />
            {/* Caption Overlay */}
            <div className="overlay">
            Find the perfect side hustle!
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default OneWideImage2;