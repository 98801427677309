import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import { NotFoundPage } from "./pages/notFoundPage";
import { Layout } from "./components/layout/Layout";
import { HomePage } from "./pages/HomePage";
import { MobileHomepage } from "./pages/MobileHomepage";
import { ItemDetailedPage } from "./pages/ItemDetailedPage";
import { AccountProfilePage } from "./pages/account/AccountProfilePage";
import { IdentityAndPayment } from "./components/account/IdentityAndPayment/IdentityAndPayment";
import { AccountBookings } from "./components/account/AccountBookings/AccountBookings";
import { AccountConversations } from "./components/account/AccountConversations";
import { AccountConversationItem } from "./components/account/AccountConversationItem";
import { AccountSettingsPage } from "./pages/account/AccountSettingsPage";
import { ItemListingPage } from "./pages/ItemListingPage";
import { AccountItems } from "./components/account/AccountItems";
import { ItemBookingConfirmation } from "./components/checkout/ItemBookingConfirmation";
import { LoadingFullScreen } from "./components/common/LoadingFullScreen";
import ScrollToTop from "./components/common/ScrollToTop";
import { ToastContainer } from "react-toastify";
import { PublicProfilePage } from "./pages/PublicProfilePage";
import { TermsPage } from "./pages/TermsPage";
import { OnboardingRequired } from "./components/terms/OnboardingRequired";
import { AuthRedirect } from "./auth0/auth-redirect";
import {
  CookieConsent,
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { AccountDashboardPage } from "./pages/account/AccountDashboardPage";
import MakeAClaim from "./components/insurance-claim/MakeAClaim";
import { AccountClaims } from "./components/account/AccountBookings/AccountClaims";
import { SearchResultsPage } from "./pages/SearchResultsPage";
import { useTranslation } from "react-i18next";

import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import { config } from '@fortawesome/fontawesome-svg-core'
import { useUserContext } from "./context/UserContext";
import BasicModal from "./components/common/BasicModal";
import { Terms } from "./components/terms/Terms";

import { HowItWorks } from "./pages/HowItWorks";
import { AboutUs } from "./pages/AboutUs";  
import { Guarantee } from "./pages/Guarantee";
import { Investors } from "./pages/Investors";
import { CompareUs } from "./pages/CompareUs";
import { CancellationPolicies } from "./pages/CancellationPolicies";
import { TermsOfService } from "./pages/TermsOfService";
import { RepairCafe } from "./pages/RepairCafe";
import { Partners } from "./pages/Partners";

import TermsModalWrapper from "./utils/TermsModalWrapper";

import { ItemMain } from "./pages/ItemMain";
import { Blog } from "./pages/blog/Blog";
import { SingleBlogPost } from "./pages/blog/SingleBlogPost";
import { PublicProfile } from "./pages/PublicProfile";
config.autoAddCss = false


const handleAcceptCookie = () => {
  try {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_WEB_PROPERTY_KEY || "");
    ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID || "", undefined);
  } catch (e) {
    /* empty */
  }
};

const handleDeclineCookie = () => {
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
};

export const GAEvent = (categoryName: any, eventName: any, value: any) => {
  try {
    ReactGA.event(eventName);
  } catch (e) {
    /* empty */
  }
};

export const FPTrack = (title: any, data: any) => {
  try {
    ReactPixel.fbq("trackCustom", title);
    ReactPixel.pageView();
  } catch (e) {
    /* empty */
  }
};

export const App: React.FC = () => {
  

  const { isLoading } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }

  }, []);


  const cache = createCache({
    key: 'cache-key',
    nonce: process.env.REACT_APP_NONCE_TOKEN,
    prepend: true,
  });

  const enableInsurance =
    process.env.REACT_APP_SHOW_INSURANCE &&
    /true/.test(process.env.REACT_APP_SHOW_INSURANCE);


    if (isLoading) {
        return (
          <LoadingFullScreen />
        );
      }
  return (
    <>
     <CacheProvider value={cache}>
      <Helmet>
        <title>RentMy</title> {/* Step 2: Add default title */}
      </Helmet>
      <ToastContainer pauseOnHover={false} />
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/blog" Component={Blog} />
          <Route path="/blog/:postid" Component={SingleBlogPost} />
          <Route path="/howitworks" Component={HowItWorks} />
          <Route path="/aboutus" Component={AboutUs} />
          <Route path="/guarantee" Component={Guarantee} />
          <Route path="/investors" Component={Investors} />
          <Route path="/compareus" Component={CompareUs} />
          <Route path="/cancellationpolicies" Component={CancellationPolicies} />
          <Route path="/termsofservice" Component={TermsOfService} />
          <Route path="/repaircafe" Component={RepairCafe} />
          <Route path="/partners" Component={Partners} />

          <Route path="/rent/:what?/:where?" Component={SearchResultsPage} />
          <Route path="/item/:refCode?/:country?/:city?/:itemName?" Component={ItemMain} />
          <Route
            path="/item-booking-confirmation"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={ItemBookingConfirmation}
                requiresVerification={true}
                requiresEmailVerification={true}
                requiresBeingSharer={false}
                requiresAuthentication={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/profile"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={AccountProfilePage}
                requiresAuthentication={true}
                requiresVerification={false}
                requiresBeingSharer={false}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/dashboard"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={AccountDashboardPage}
                requiresAuthentication={true}
                requiresVerification={true}
                requiresBeingSharer={false}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/verification"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={IdentityAndPayment}
                requiresAuthentication={true}
                requiresVerification={false}
                requiresBeingSharer={false}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route path="/public/profile" Component={PublicProfile} />
          <Route
            path="/account/my-items"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={AccountItems}
                requiresAuthentication={true}
                requiresVerification={true}
                requiresBeingSharer={true}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/my-bookings"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={(props: any) => (
                  <AccountBookings {...props} isSharerView={true} />
                )}
                requiresAuthentication={true}
                requiresVerification={true}
                requiresBeingSharer={true}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/my-claims"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={(props: any) => (
                  <AccountClaims {...props} isSharerView={true} />
                )}
                requiresAuthentication={true}
                requiresVerification={true}
                requiresBeingSharer={true}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/my-rentals"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={(props: any) => (
                  <AccountBookings {...props} isSharerView={false} />
                )}
                requiresAuthentication={true}
                requiresVerification={true}
                requiresBeingSharer={false}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/messages"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={AccountConversations}
                requiresVerification={true}
                requiresEmailVerification={true}
                requiresBeingSharer={false}
                requiresAuthentication={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/messages/:subject/:otherParticipantId"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={AccountConversationItem}
                requiresVerification={true}
                requiresEmailVerification={true}
                requiresBeingSharer={false}
                requiresAuthentication={true}
                requiresOnboarding={true}
              />
            )}
          />
          <Route
            path="/account/settings"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={AccountSettingsPage}
                requiresAuthentication={true}
                requiresVerification={false}
                requiresBeingSharer={false}
                requiresEmailVerification={false}
                requiresOnboarding={false}
              />
            )}
          />
          <Route path="/terms" Component={TermsPage} />
          <Route
            path="/item-listing"
            Component={(props) => (
              <OnboardingRequired
                {...props}
                Component={ItemListingPage}
                requiresBeingSharer={true}
                requiresAuthentication={true}
                requiresVerification={true}
                requiresEmailVerification={true}
                requiresOnboarding={true}
              />
            )}
          />
          {enableInsurance && (
            <Route
              path="/insurance-claim/:bookingid?"
              element={
                <OnboardingRequired
                  requiresBeingSharer={true}
                  Component={(props: any) => (
                    <MakeAClaim {...props} isSharerView={true} />
                  )}
                  requiresAuthentication={true}
                  requiresVerification={true}
                  requiresEmailVerification={true}
                  requiresOnboarding={true}
                    />
                  }
                />
              )}
    
              <Route path="/sign-up" Component={() => AuthRedirect("signup")} />
              <Route path="/login" Component={() => AuthRedirect("login")} />
              <Route path="*" Component={NotFoundPage} />
            </Routes>

            <TermsModalWrapper/>
            
          </Layout>
          <div className="cookies">
            <CookieConsent
              enableDeclineButton
              buttonText="Accept"
              buttonClasses={"cookie-accept-button"}
              declineButtonClasses={"cookie-manage-button"}
              declineButtonText="Reject"
              onAccept={handleAcceptCookie}
              onDecline={handleDeclineCookie}
            >
              {t("cookie_consent_message")}
            </CookieConsent>
          </div>
         
          </CacheProvider>
           
        </>
      );
    };
    
    export default App;