// HowItWorks.tsx
// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";

interface Media {
  system: { name: string };
  fields: { umbracoFile: { src: string } };
}

interface Content {
  system: { name: string };
  fields: {
    button1_1: string;
    button1_2: string;
    header2_1: string;
    body2_1: string;
    media2_1: Media[];
    header2_2: string;
    body2_2: string;
    media2_2: Media[];
    header2_3: string;
    body2_3: string;
    media2_3: Media[];
    header3_1: string;
    body3_1: string;
    media3_1: Media[];
    header3_2: string;
    body3_2: string;
    media3_2: Media[];
    header3_3: string;
    body3_3: string;
    media3_3: Media[];
  };
}

export function HowItWorks() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);
  // By default, show the sharers section (section #3)
  const [currentSection, setCurrentSection] = useState(3);

  const { REACT_APP_CMS_URL, REACT_APP_CMS_HOW_IT_WORKS } = process.env;

  const getContent = async () => {
    setLoading(true);
    try {
      const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_HOW_IT_WORKS);
      setContent(res.data as Content);
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  const getImage = (media: Media) =>
    REACT_APP_CMS_URL + media.fields.umbracoFile.src;

  useEffect(() => {
    getContent();
  }, []);

  const ImageSlider = ({ media }: { media: Media[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    // Auto-slide every 1.8s on desktop
    useEffect(() => {
      if (window.innerWidth > 768 && media.length > 1) {
        intervalRef.current = setInterval(() => {
          setCurrentIndex((prev) => (prev + 1) % media.length);
        }, 1800);
        return () => clearInterval(intervalRef.current as NodeJS.Timeout);
      }
    }, [media]);

    return (
      <div className="image-slider-wrapper position-relative">
        <div className="image-slider">
          {media.map((m, idx) => (
            <div
              key={idx}
              className={`image-slide ${idx === currentIndex ? "active" : ""}`}
            >
              <img
                src={getImage(m)}
                alt={m.system.name}
                className="img-fluid slider-image"
                loading="lazy"
              />
            </div>
          ))}
        </div>

        {media.length > 1 && (
          <div className="slider-dots">
            {media.map((_, idx) => (
              <span
                key={idx}
                className={`dot ${idx === currentIndex ? "active" : ""}`}
                onClick={() => setCurrentIndex(idx)}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMedia = (media: Media[]) => {
    if (!media || media.length === 0) return null;
    if (media.length > 1) {
      return <ImageSlider media={media} />;
    }
    return (
      <img
        alt={media[0].system.name}
        src={getImage(media[0])}
        className="img-fluid slider-image"
      />
    );
  };

  if (loading || !content) {
    return (
      <div className="spinner-container">
        <SmallLoadingSpinner />
      </div>
    );
  }

  return (
    <Container className="mt-2 static-pages-wrapper">
      {/* Top Row with two buttons */}
      <Row className="section">
      <Col xs={12} className="d-flex flex-row justify-content-center align-items-center button-row">  {[
            { section: 2, label: content.fields.button1_1 },
            { section: 3, label: content.fields.button1_2 },
             ].map((btn, index) => (
              <button
              key={index}
              className={`how-it-works-buttons ${
                currentSection === btn.section ? "active-btn" : ""
              }`}
              onClick={() => setCurrentSection(btn.section)}
            >
              {btn.label}
            </button>
          ))}
</Col>
      </Row>

      {/* Section for “For Renters” (2) */}
      {currentSection === 2 && (
        <Row className="section howitworks-section">
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column justify-content-start align-items-start text-left"
          >
            <h2>{content.fields.header2_1}</h2>
            <div>{parse(content.fields.body2_1 || "")}</div>

            <h2>{content.fields.header2_2}</h2>
            <div>{parse(content.fields.body2_2 || "")}</div>

            <h2>{content.fields.header2_3}</h2>
            <div>{parse(content.fields.body2_3 || "")}</div>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
            {renderMedia(content.fields.media2_1)}
          </Col>
        </Row>
      )}

      {/* Section for “For Sharers” (3) */}
      {currentSection === 3 && (
        <Row className="section howitworks-section">
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column justify-content-start align-items-start text-left"
          >
            <h2>{content.fields.header3_1}</h2>
            <div>{parse(content.fields.body3_1 || "")}</div>

            <h2>{content.fields.header3_2}</h2>
            <div>{parse(content.fields.body3_2 || "")}</div>

            <h2>{content.fields.header3_3}</h2>
            <div>{parse(content.fields.body3_3 || "")}</div>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
            {renderMedia(content.fields.media3_1)}
          </Col>
        </Row>
      )}

      <Footer />
    </Container>
  );
}