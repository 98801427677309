import axios from "axios";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import { ExploreTopCategories } from "../components/home/ExploreTopCategories";
import LogoStrip from "../components/home/LogoStrip";

interface Content {
  system: { name: string };
  fields: {
    header: string;
    header2_1: string;
    body2_1: string;
    header2_2: string;
    body2_2: string;
    header2_3: string;
    body2_3: string;
    header2_4: string;
    body2_4: string;
    header2_5: string;
    body2_5: string;
    header2_6: string;
    body2_6: string;
    header2_7: string;
    body2_7: string;
    header2_8: string;
    body2_8: string;
    header2_9: string;
    body2_9: string;
    header3_1: string;
    body3_1: string;
  };
}

export function CancellationPolicies() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_CMS_URL, REACT_APP_CMS_CANCELLATION_POLICY } = process.env;

  const getContent = async () => {
    setLoading(true);
    try {
      if (REACT_APP_CMS_URL && REACT_APP_CMS_CANCELLATION_POLICY) {
        const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_CANCELLATION_POLICY);
        setContent(res.data as Content);
      } else {
        console.error("CMS URL or Cancellation Policy endpoint is not defined");
      }
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getContent();
  }, []);

  return loading || !content ? (
    <div className="spinner-container">
      <SmallLoadingSpinner />
    </div>
  ) : (
    <Container className="mt-2 static-pages-wrapper">
      <Row>
        <Col xs={12}>
          <h1 className="text-center">{content.system.name}</h1>
        </Col>
      </Row>

      {/* Section 1 */}
      <Row className="section">
        <Col xs={12}>
          <h3>{content.fields.header}</h3>
        </Col>
      </Row>

      {/* Section 2 */}
      {[1, 2, 3, ].map((index) => (
        <Row key={index} className="section align-items-center">
          <Col xs={12}>
            <h3>{content.fields[`header2_${index}`]}</h3>
            {parse(content.fields[`body2_${index}`] || "No content available")}
          </Col>
        </Row>
      ))}

      {/* Section 3 */}
      <Row className="section align-items-center">
        <Col xs={12}>
          <h3>{content.fields.header3_1}</h3>
          {parse(content.fields.body3_1 || "No content available")}
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}