import React, { useEffect, useState, useContext, useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import PlaceResult = google.maps.places.PlaceResult;
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../context/LocationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faLocationDot, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useLocation} from "react-router-dom";
import { parsePath } from "../../utils/url";

export default function LocationSearchField ({localSelectedPlace, setLocalSelectedPlace, mobile = false, homepage = false}) {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
    const [hasClicked, setHasClicked] = useState(false);
    const autoCompleteField = useRef();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const {where:whereFromUrl} = parsePath(pathname);
    const [hasWhereFromUrl, setHasWhereFromUrl] = useState(whereFromUrl ?? false);

    const {            
        automaticLocationSearchLoading,
        getUsersLocation,
        usersLocationCity
    } = useContext(LocationContext)

    const getLocationOnFocus = async () => {
        // users has tried this once...
        if(hasClicked) return;
        // already a location in url
        if(whereFromUrl) return;
        setHasClicked(true)
        getLocation()
    }

    const getLocationOnClick = () => {
        //@ts-ignore
        setHasWhereFromUrl(false);
        getLocation()
    }

    const getLocation = async () => {
        const location = await getUsersLocation(false);
        setLocalSelectedPlace(location);
    }

    const clear = () => {
        setLocalSelectedPlace(undefined);
          //@ts-ignore
        autoCompleteField.current.value = ""
    }

    const RenderPin = ({children}) => {
        return !mobile ? children : (
            <label>{children}</label>
        )
    }

    useEffect(() => {

        if(!autoCompleteField.current) return () => {}

         //@ts-ignore
         autoCompleteField.current.value = hasWhereFromUrl 
            ? whereFromUrl 
            : usersLocationCity
    }, [usersLocationCity, hasWhereFromUrl, whereFromUrl])

    return (
        <>
            <RenderPin>
                <FontAwesomeIcon
                    className="location-icon clickable"
                    icon={automaticLocationSearchLoading ? faSpinner : faLocationDot}
                    color={automaticLocationSearchLoading ? "#b3bac2" : "#EA554A"}
                    fontSize="20px"
                    spin={automaticLocationSearchLoading}
                    onClick={getLocationOnClick}
                />
                { mobile && <>Where</> }
               
            </RenderPin>
            <Autocomplete
                //@ts-ignore
                ref={autoCompleteField}
                onFocus={getLocationOnFocus}
                onInput={(e: any) => {
                    if (e.target.value.trim() === "") {}
                        setLocalSelectedPlace(undefined);
                    }
                }
                //@ts-ignore
                placeholder={t("homepage_where_you_want_it_short")}
                options={{
                    types: ["(cities)"],
                    componentRestrictions: { country: "uk" },
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && !localSelectedPlace) {
                        event.preventDefault();
                    }
                }}
                apiKey={apiKey}
                className={homepage ? "form-control fit-within-mobile-view" : "autocomplete"}
                onPlaceSelected={(place: PlaceResult) => setLocalSelectedPlace(place)}
        />
            {!!localSelectedPlace && !mobile && (
                <FontAwesomeIcon
                    className="mr-1 clickable clear-text"
                    icon={faCircleXmark}
                    color="#b3bac2"
                    fontSize="16px"
                    onClick={clear}
                />
            )}
      </>

    )

}