import * as React from "react";
import { useEffect, useState } from "react";
import { IConfig, ItemClient } from "../../api/rentMyApi";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function Reviews({ reviews }) {
  const { t } = useTranslation();
  return (
    <Row className="single-item-reviews">
      {reviews && reviews.length > 0 ? (
        <>
          {reviews.map((review) => {
            return (
              <Col xs={12} key={review.id}>
                <div className="single-review-row">
                  <div className="condensed-user-row">
                    {review.reviewer.profileImage && (
                      <div className="row-left">
                        <div
                          className="renters-avatar"
                          style={{
                            backgroundImage: `url("${review.reviewer.profileImage?.path}")`,
                          }}
                        ></div>
                      </div>
                    )}
                    <div className="content-right">
                      <span className="small-text">{t("reviewed_by")}</span>
                      <div className="user-meta">
                        <h2>{review.reviewer.name}</h2>
                        <p className="small-text">
                          &nbsp;{moment(review.creation).fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                  {review.description && review.description.length > 0 && (
                    <p className="single-review-text">"{review.description}"</p>
                  )}

                  <Row className="review-metrics">
                    {reviewMetrics.map((metric) => {
                      return (
                        <Col xs={6} md={4} key={metric.key}>
                          <div className="review-metric">
                            <div className="review-star">
                              <FontAwesomeIcon icon={faStar} />
                              <span>{review[metric.key]}</span>
                            </div>
                            <span>{metric.label}</span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
            );
          })}
        </>
      ) : (
        <div className="no-data-display">
          <p>{t("no_reviews_yet")}</p>
        </div>
      )}
    </Row>
  );
}

const reviewMetrics = [
  {
    label: "Communication",
    key: "communicationRating",
  },
  {
    label: "Accuracy",
    key: "accuracyRating",
  },
  {
    label: "Drop Off",
    key: "dropOffRating",
  },
  {
    label: "Pick Up",
    key: "pickUpRating",
  },
  {
    label: "Quality",
    key: "qualityRating",
  },
  {
    label: "Value",
    key: "valueRating",
  },
];
