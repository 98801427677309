import React, {useEffect, useState} from "react";
import BasicModal from "../components/common/BasicModal";
import { Terms } from "../components/terms/Terms";
import { useUserContext } from "../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { IConfig, TermsClient } from "../api/rentMyApi";
import { termsOfServiceType } from "../components/terms/TermsRedirect";
import { useLocation } from 'react-router-dom';
import { LoadingFullScreen } from "../components/common/LoadingFullScreen";

export default function TermsModalWrapper() {

    const { hasAcceptedTerms, updateAcceptedTerms } = useUserContext();
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const { getAccessTokenSilently, isAuthenticated, isLoading, getIdTokenClaims, user: auth0User, logout } = useAuth0();
    const location = useLocation();

    const toggleTermsModal = () => {
        setTermsModalOpen(!termsModalOpen);
    };
    
    const getTerms = async () => {
        let token;
        let claims;
        try {
            token = await getAccessTokenSilently({ignoreCache: true});
            claims = await getIdTokenClaims()
        } catch(err) {
            console.log(err)
            logout();
            return;
        }
        if(!token) return;
        if(!claims) return;
        if(!claims.email_verified) return;

        const termsClient = new TermsClient(
          new IConfig(token),
          process.env.REACT_APP_API_ENDPOINT
        );

        termsClient.toAccept()
            .then((response) => {
                // new terms were received so launch modal
                 if(response) {
                     setTermsModalOpen(true)
                 } else {
                    updateAcceptedTerms(true)
                 }
            }).catch((x) => {
                console.log("Terms caught an error so doing nothing...", x)
                // user is logged in and verified but api still says no
                // seems to work after a short amount of time
                setTimeout(()=> {
                    getTerms();
                }, 5000)
            });
    
    }
    useEffect(() => {
        if(auth0User && isAuthenticated && !isLoading && !hasAcceptedTerms) {
            getTerms();
        }
    }, [isAuthenticated, isLoading, location.pathname, auth0User])

    useEffect(() => {
        if(hasAcceptedTerms) {
            setTermsModalOpen(false)
        }
    }, [hasAcceptedTerms])

  return  (  
    <BasicModal
        modalBody={<Terms 
            withAcceptButton={true}
            itemListingPayload={undefined}
            itemBookingPayload={undefined}
            isLogin={undefined}
        />}
        modalState={termsModalOpen}
        toggleModal={toggleTermsModal}
        dismissable={false}
    /> 
  );

}