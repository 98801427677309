// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import { PopularSlider } from "../components/home/PopularSlider";
import LogoStrip from "../components/home/LogoStrip";

interface Media {
  system: { name: string };
  fields: { umbracoFile: { src: string } };
}

interface Content {
  system: { name: string };
  fields: {
    header1_1: string;
    body1_1: string;
    media1_1: Media[];
    header2_1: string;
    body2_1: string;
    media2_1: Media[];
    header2_2: string;
    body2_2: string;
    media2_2: Media[];
    header2_3: string;
    body2_3: string;
    media2_3: Media[];
    header3_1: string;
    body3_1: string;
    media3_1: Media[];
    header4_1: string;
    body4_1: string;
    media4_1: Media[];
  };
}

export function AboutUs() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_CMS_URL, REACT_APP_CMS_ABOUT_US } = process.env;

  const getContent = async () => {
    setLoading(true);
    try {
      const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_ABOUT_US);
      setContent(res.data as Content);
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  const getImage = (media: Media) => {
    return media?.[0] ? REACT_APP_CMS_URL + media[0].fields.umbracoFile.src : "";
  };

  useEffect(() => {
    getContent();
  }, []);

  const renderMedia = (media: Media[]) => (
    <img
      alt={media?.[0]?.system?.name || "No image"}
      src={getImage(media)}
      className="img-fluid"
    />
  );

  return loading || !content ? (
    <div className="spinner-container">
      <SmallLoadingSpinner />
    </div>
  ) : (
    <Container className="mt-2 static-pages-wrapper">
  
      {/* Section 1 */}
      <Row className="section align-items-center">
        <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-start order-2 order-md-1">
          <h2>{content.fields.header1_1}</h2>
          {parse(content.fields.body1_1 || "No content available")}
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center order-1 order-md-2">
          {renderMedia(content.fields.media1_1)}
        </Col>
      </Row>

      {/* Section 2 */}
      <Row className="section align-items-center">
        <Col xs={12} md={4} className="d-flex flex-column align-items-center text-center">
          {renderMedia(content.fields.media2_1)}
          <h2>{content.fields.header2_1}</h2>
          {parse(content.fields.body2_1 || "No content available")}
        </Col>
        <Col xs={12} md={4} className="d-flex flex-column align-items-center text-center">
          {renderMedia(content.fields.media2_2)}
          <h2>{content.fields.header2_2}</h2>
          {parse(content.fields.body2_2 || "No content available")}
        </Col>
        <Col xs={12} md={4} className="d-flex flex-column align-items-center text-center">
          {renderMedia(content.fields.media2_3)}
          <h2>{content.fields.header2_3}</h2>
          {parse(content.fields.body2_3 || "No content available")}
        </Col>
      </Row>

      {/* Section 3 */}
      <Row className="section align-items-center">
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
          {renderMedia(content.fields.media3_1)}
        </Col>
        <Col xs={12} md={6} className="d-flex flex-column align-items-start">
          <h2>{content.fields.header3_1}</h2>
          {parse(content.fields.body3_1 || "No content available")}
        </Col>
      </Row>
              {/* Section 4 */}
      <Row className="section align-items-center">
      <Col xs={12} md={6} className="d-flex flex-column align-items-start">
          <h2>{content.fields.header4_1}</h2>
          {parse(content.fields.body4_1 || "No content available")}
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
          {renderMedia(content.fields.media4_1)}
        </Col>
      </Row>

      {/* Footer */}
      <>
        <PopularSlider />
        <LogoStrip />
        <Footer />
      </>
    </Container>
  );
}
