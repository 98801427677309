// import React from "react";
import axios from "axios";
import { Loader } from "@googlemaps/js-api-loader";

export async function getIPAddress(): Promise<string | null> {
  let response;
  try {
    response = await axios.get(`https://api.ipify.org/?format=json`);
  } catch (err) {
    console.log("no IP address returned: ", err);
    return null;
  }
  return response?.data?.ip || null;
}

export async function getLocationFromLatLng(coords) {
  const lat = coords.latitude;
  const lng = coords.longitude;

  const placesService = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
  });

  return placesService.load().then(async () => {
    const geocoding = await google.maps.importLibrary("geocoding");
    // @ts-ignore
    const geocoder = new geocoding.Geocoder({
      types: ["locality", "political"],
    });
    let result;
    try {
      result = await geocoder.geocode({
        location: new google.maps.LatLng(lat, lng),
        fulfillOnZeroResults: true,
      });
    } catch (err) {
      console.log(err);
      return null;
    }
    return result.results?.[0];
  });
}
