import React, { Suspense } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithHistory } from "./auth0/auth0-provider-with-history";
import { UserContextProvider } from "./context/UserContext";
import { LocationContextProvider } from "./context/LocationContext";

import { EnvProvider } from "./context/env.context";
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { store } from "./redux/store";


import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import isTouchDevice from "./utils/isTouchDevice";
import { Provider } from "react-redux";

const loadingMarkup = (<h1></h1>);

const theme = createTheme({
  typography: {
    fontFamily: "\"Work Sans\",sans-serif !important"
  }
});

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOMClient.createRoot(rootElement);
  root.render(
    <Suspense fallback={loadingMarkup}>
      <BrowserRouter>
        <Provider store={store}>
          <EnvProvider>
            <Auth0ProviderWithHistory>
              <UserContextProvider>
                <LocationContextProvider>
                <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
                  <ThemeProvider theme={theme}>
                    <App />
                  </ThemeProvider>
                </DndProvider>
                </LocationContextProvider>
              </UserContextProvider>
            </Auth0ProviderWithHistory>
          </EnvProvider>
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
} else {
  // console.error('Could not find root element');
}

serviceWorkerRegistration.register();