import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  const [footerData, setFooterData] = useState({
    brandUrl: "",
    brandImagePath: "",
  });

  // Footer link sections
  const section2Links = [
    { href: "/howitworks", text: "How It Works" },
    { href: "/aboutus", text: "About Us" },
    { href: "/guarantee", text: "Guarantee" },
    { href: "/compareus", text: "Compare Us" },
    { href: "/blog", text: "Latest Stories" },
  ];

  const section3Links = [
    { href: "/cancellationpolicies", text: "Cancellation Policies" },
    { href: "/termsofservice", text: "Terms of Service" },
    { href: "/repaircafe", text: "Repair Cafe" },
    { href: "/partners", text: "Partners" },
    { href: "/investors", text: "Investors" },
  ];

  // Example colour to match your links
  const footerLinkColor = "#555";

  useEffect(() => {
    axios
      .get("/api/footer")
      .then((res) => setFooterData(res.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <section className="footer">
      <Container>
        <div className="row">
          {/* Left-aligned logo + social icons */}
          <div
            className="col-12 col-sm-6 col-md-3 d-flex flex-column align-items-start"
            style={{ textAlign: "left" }}
          >
            <a href={footerData.brandUrl} title="" rel="noreferrer">
              <img
                src="/assets/img/RentMy-logo-no-tm.webp"
                alt="RentMy - Rent Anything, Anywhere"
                width="130"
                height="auto"
              />
            </a>

            <div className="social-icons mt-3">
              <p style={{ marginBottom: "0.5rem", color: footerLinkColor }}>
                Follow us on
              </p>
              <div className="social-icon-links">
                <a
                  href="https://www.facebook.com/rentmycom"
                  title="Facebook"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontSize: "1.5rem",
                    color: footerLinkColor,
                    marginRight: "1rem",
                  }}
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  href="https://www.instagram.com/rentmyuk/"
                  title="Instagram"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontSize: "1.5rem",
                    color: footerLinkColor,
                    marginRight: "1rem",
                  }}
                >
                  <i className="fab fa-instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/rentmydotcom"
                  title="LinkedIn"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: "1.5rem", color: footerLinkColor }}
                >
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
          </div>

          {/* Two columns of footer links */}
          {[section2Links, section3Links].map((group, groupIndex) => (
            <div key={groupIndex} className="col-12 col-sm-6 col-md-3">
              <ul>
                {group.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a
                      href={link.href}
                      style={{
                        textDecoration: "none",
                        color: footerLinkColor,
                        fontFamily: "inherit",
                      }}
                    >
                      <p className="footer-link-text">{link.text}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Footer;