
import React, { createContext, useEffect, useState } from "react";
import { getLocationFromLatLng } from "../api/externalServices";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import locationFromSearchResult from "../utils/locationFromSearchResult";
// @ts-ignore
const LocationContext = createContext({
    automaticLocationSearch: false,
    setAutomaticLocationSearch: (boolean) => {},
    automaticLocationSearchLoading : false,
    getUsersLocation:(boolean) => {},
    usersLocationCity: null as string | null
});


/*
    Auto get users location based on browser data
*/
const LocationContextProvider = ({children}) => {
    const { t } = useTranslation();
    const [automaticLocationSearch, setAutomaticLocationSearch] = useState<boolean>(
        false
    );
    const [automaticLocationSearchLoading, setAutomaticLocationSearchLoading] = useState<boolean>(
        false
    );
    const [usersLocationCity, setUsersLocationCity] = useState<string | null>(
        null
    );

    const getUsersLocation = async (showErrorToast) => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                setAutomaticLocationSearch(true)
                setAutomaticLocationSearchLoading(true)
                navigator.geolocation.getCurrentPosition(async function(location) {
                const usersLocation = await getLocationFromLatLng(location.coords)
                    setAutomaticLocationSearchLoading(false)
                    if(usersLocation) {
                        setUsersLocationCity(locationFromSearchResult(usersLocation))
                        resolve(usersLocation)
                    } else {
                        setUsersLocationCity(null)
                        setAutomaticLocationSearch(false)
                        resolve(undefined)
                    }
                    }, function (error) {
                        setAutomaticLocationSearch(false)
                        setAutomaticLocationSearchLoading(false)
                        setUsersLocationCity(null)
                        if(showErrorToast) {
                            toast.error(t('homepage_cannot_use_location'), {
                                position:"bottom-center"
                            })
                        }
                        resolve(undefined)
                    });
            } else {
                setAutomaticLocationSearch(false)
                setAutomaticLocationSearchLoading(false)
                setUsersLocationCity(null)
                if(showErrorToast) {
                    toast.error(t('homepage_cannot_find_location'), {
                        position:"bottom-center"
                    })
                }
                resolve(undefined);
            }
        })
    }

    useEffect(() => {
       // getUsersLocation(false)
    }, []);

    return (
    <LocationContext.Provider
        value={{
            automaticLocationSearch,
            setAutomaticLocationSearch,
            automaticLocationSearchLoading,
            getUsersLocation,
            usersLocationCity
        }}>
        {children}
    </LocationContext.Provider>
)
}

export { LocationContext, LocationContextProvider };
